@import "~bootstrap/scss/bootstrap";

*{
  font-family: 'Poppins', sans-serif;
}
body{
  background-color: #F1F1F1;
}

$color-primary : #172331;
$color-secondary :  #79BEF1;

.color-primary{
  color: $color-primary !important;
}
.color-secondary{
  color: $color-secondary !important;
}
.normal-font{
  font-weight: normal !important;
  font-size: 13px;
}


.login-container{
  padding: 0;
  width: 100%;
  background-color: white;
  @media screen and (max-width: 768px) {
    --bs-gutter-x: 0;
  }
  .login-right-container{
    z-index: 1;
    @media screen and (max-width: 768px) {
      background-color: rgba(255,255,255,0.95);
      max-width: 100%;
      padding: 10px 50px;
      min-height: 100vh;
      display: flex;
      align-items: center;
    }
  }
  .login-form-container{
    max-width: 450px;
    z-index: 1;
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }
  .sign-up-container{
    margin-top: 150px;
  }
  .login-img , .login-img > div{
    padding:0;
    height:100vh;
    overflow:hidden;
    @media screen and (max-width: 767px) {
      position:absolute;
      top:0;
      z-index:0;
      min-height:100vh;
    }
  }
  .form-control{
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid rgba(121, 190, 241, 0.3) !important;
    padding-left: 0 !important;
    font-weight: normal !important;
    font-size: 13px;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #172331;
      font-weight: normal;
      opacity: 0.7;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #172331;
      font-weight: normal;
      opacity: 0.7;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #172331;
      font-weight: normal;
      opacity: 0.7;
    }
  }
  .form-check-label{
    font-weight: normal !important;
    font-size: 13px;
  }
  .form-check-input:checked {
    background-color: $color-secondary;
    border-color: $color-secondary;
  }
  .forgot-pw{
    @extend .color-secondary, .link-custom;
    font-weight: 400 !important;
    font-size: 13px;
    font-style: italic;
    cursor: pointer;
  }
}

.brand-container{
  font-size: 25px;
  font-weight: 600;
  div:first-child{
    @extend .color-primary
  }
  div:last-child{
    @extend .color-secondary
  }
}

.button-custom{
  @extend .normal-font;
  width: 100%;
  padding: 13px 10px;
  background: #79BEF1;
  box-shadow: 0px 8px 24px rgba(121, 190, 241, 0.25);
  border-radius: 16px;
  color: white !important;
}

.link-custom{
  cursor: pointer;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

.container-new{
  @media (min-width: 992px){
    max-width: 800px;
  }
  @media (min-width: 1200px){
    max-width: 900px;
  }
  @media (min-width: 1400px){
    max-width: 1000px;
  }
}


.header-container{
  background-color: $color-secondary;
  height: 70px;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  .header-topic{
    font-size: 20px;
  }
  .dropdown-user-options button{
    background-color: transparent !important;
    border: none;
    padding: 0;
  }
  #dropdown-basic{
    max-height: 70px;
    box-shadow: none !important;
  }
  #dropdown-basic::after{
    display: none;
  }
  .dropdown-menu{
    padding: 0;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.05);
    border-radius: 8px 0px 8px 8px;
    border: none;
    .dropdown-item{
      padding: 10px 0px;
      &:first-child{
        border-top-left-radius: 8px;
      }
      &:last-child{
        border-bottom-right-radius: 8px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 12px;
    .header-topic{
      font-size: 18px;
    }
  }
}

.round-container{
  background-color: #FFFFFF;
  border-radius: 16px;
  align-items: center;
  padding: 20px 13px;
}

.detail-item-container{
  @extend .round-container;
  max-width: 280px;
  width: 280px;
  .icon-container{
    background-color: #F2FAFF;
    padding: 13px 6px;
    border-radius: 8px;
  }
  .detail-container{
    div{
      max-height: 30px;
    }
    div:first-child{
      font-size: 30px;
      font-weight: bold;
      margin-top: -10px;
      padding-top: 5px;
      margin-bottom: 10px;
    }
    div:last-child{
      font-size: 13px;
      font-weight: lighter;
      padding-top: 5px;
    }
  }
}


.file-drop{
  cursor:pointer;
  background-color: #F2FAFF;
  height:80px;
  border-radius:5px;
  .file-name{
    margin: 0 10px;
    font-size: 12px;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.file-drop-target{
  width: 100%;
  height: 100%;
  text-align:center;
  display:flex;
  div{
    pointer-events:none;
    align-items:center;
    margin:auto
  }
}
.file-drop > .file-drop-target.file-drop-dragging-over-target {
  box-shadow: 0 0 13px 3px #f4f4f4;
  background: rgba(242, 250, 255, 0.1) !important;
}

.react-bootstrap-table{
  min-width: 600px;
}
.job-table-class{
  background-color: white;
  border-radius: 16px 16px 0 0;
  border-color: transparent;
  .job-table-header-class{
    th{
      @extend .normal-font;
      font-weight: bold !important;
      text-align: center;
      height: 50px;
      padding-bottom: 13px;
    }
  }
  .job-table-body-class{
    td{
      @extend .normal-font;
      text-align: center;
      border-bottom: 1px solid #F2FAFF;
      line-height: 30px;
    }
  }
  .expanded-area{
    background-color: #F2FAFF;
    border-radius: 8px;
    padding: 20px 30px;
    .value-area{
      @extend .round-container;
      border-radius: 9px;
    }
  }
}
.table > :not(:first-child) {
  border-top: 2px solid #F2FAFF;
}

.download-column{
  div {
    div {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.job-detail-container{
  >div{
    text-align: center;
    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }
    div:first-child{
      color: #848484;
      font-weight: 200;
      font-size: 13px;
    }
  }
}

.value-area{
  border: 1px solid $color-secondary;
  border-radius: 8px;
  padding: 10px 11px 0 !important;
  .data-row{
    padding: 5px 10px;
    div:first-child{
      width: 200px;
    }
    div:last-child{
      width: calc(100% - 200px);
    }
  }
  .scrollable-area{
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .data-main-row{
    border-bottom: 1px solid #e4f1fa;
  }
}

.breadcrumb-custom a{
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}